.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.Logo{
  max-width: 650px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@font-face {
  font-family: 'Nineteen Ninety Seven';
  src: url("./fonts/Nineteen-Ninety-Seven.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Space Mono';
  src: url("./fonts/SpaceMono-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.MainApp{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.5vmin;
  text-align: center;
  color: white;
}

h1{
  font-family: 'Nineteen Ninety Seven';
  font-weight: normal;
  color: white;
}

h3{
  font-family: 'Nineteen Ninety Seven';
  font-weight: normal;
  color: white;
  text-shadow: 2px 2px #4dd70c;
}

.special-z{
  color: #a440f1;
}

.ImageCover{
  width: 100%;
  height: 100%;
  z-index: -1;
  position: absolute;
  opacity: 1;
  object-fit: cover;
}

.TitleText{
  font-size: 3rem;
}

.SubTitleText{
  font-size: 1.5rem;
  font-weight: 600;
  margin-top: 0px;
}

.TextBox{
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -40%);
  color: white;
  font-size: 1.5em;
  font-weight: normal;
  text-align: center;
  z-index: 1;
}

.ButtonBox{
  position: absolute;
  bottom: 10vh;
  color: white;
  font-weight: normal;
  text-align: center;
  z-index: 1;
}

.HiddenOverflow{
  overflow-x: hidden;
}

.JPEGZButton{
  font-family: 'Space Mono';
  color: white;
  background-color: transparent;
  border: 2px solid #a440f1;
  padding: 15px;
  font-size: 1.5em;
  font-weight: normal;
  text-align: center;
  z-index: 1;
  margin: 10px;
}

.JPEGZButton:hover{
  font-family: 'Space Mono';
  color: white;
  background-color: #a440f13b;
  border: 2px solid #a440f1;
  padding: 15px;
  font-size: 1.5em;
  font-weight: normal;
  text-align: center;
  z-index: 1;
  margin: 10px;
  cursor: pointer;
  box-shadow: 0 0 12px 3px rgba(164,64,242,.8);
  transform: scale(1.01);
}


@media only screen and (max-device-width: 600px) and (orientation: portrait) {
  .JPEGZButton{
    color: white;
    font-family: 'Space Mono';
    background-color: transparent;
    border: 2px solid #a440f1;
    padding: 10px;
    font-size: 1.5em;
    font-weight: normal;
    text-align: center;
    z-index: 1;
    margin: 10px;
    width: 100%;
  }

  .JPEGZButton:hover{
    color: white;
    background-color: transparent;
    border: 2px solid #a440f1;
    padding: 10px;
    font-size: 1.5em;
    font-weight: normal;
    text-align: center;
    z-index: 1;
    margin: 10px;
    cursor: pointer;
    width: 100%;
  }

  .ButtonBox{
    position: absolute;
    right: auto;
    bottom: 5vh;
    color: white;
    font-weight: normal;
    text-align: center;
    z-index: 1;
  }

  .TextBox{
    position: absolute;
    left: auto;
    top: auto;
    transform: unset;
  }

  .Logo{
    max-width: 100%;
  }

}

